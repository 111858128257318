<template>
    <InviteTeammate :showEmail="showEmail" :toggleEmail="toggleEmail" />
    <div class="navigation-tabs">
        <div class="flex flex-row items-center justify-start">
            <h1 class="title">{{ this.Store.company_name }}</h1>
            <Popper class="popper-icon" :content="$t('invite team members')" placement="bottom" :hover="true">
                <button class="rounded-full text-lg text-[#7D8FB3]" @click="toggleEmail">
                    <font-awesome-icon class="user-icon" :icon="['fas', 'user-plus']" />
                </button>
            </Popper>
        </div>
        <div class="nav-links">
            <!-- <router-link to="/tasks-list" @click="selectTab(0)" :class="{ active: selected[0] }"> -->
            <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/list-tasks.svg" alt="list-tasks"> -->
            <!-- <font-awesome-icon :icon="['fas', 'list-check']" class="nav-icon" />
                <span>List Tasks</span>
            </router-link>
            <router-link id="active" to="/boards" @click="selectTab(1)" :class="{ active: selected[1] }"> -->
            <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/boards.svg" alt="boards"> -->
            <!-- <font-awesome-icon :icon="['fab', 'flipboard']" class="nav-icon" />
                <span>Boards</span>
            </router-link>
            <router-link to="/calendar" @click="selectTab(2)" :class="{ active: selected[2] }"> -->
            <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/Calendar.svg" alt="calendar"> -->
            <!-- <font-awesome-icon :icon="['fas', 'calendar-days']" class="nav-icon" />
                <span>Calendar</span>
            </router-link>
            <router-link to="#" @click="selectTab(3)" :class="{ active: selected[3] }"> -->
            <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/gantt.svg" alt="gantt"> -->
            <!-- <font-awesome-icon :icon="['fas', 'chart-gantt']" class="nav-icon" />
                <span>Gantt</span>
            </router-link>
            <router-link to="#" @click="selectTab(4)" :class="{ active: selected[4] }"> -->
            <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/activity-up.svg" alt="activity"> -->
            <!-- <font-awesome-icon :icon="['fas', 'arrow-trend-up']" class="nav-icon" />
                <span>Activity</span>
            </router-link> -->
            <router-link to="/archive" @click="selectTab(2)" :class="`${this.$route.path == '/archive' ? 'active ' : ''}`">
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/boards.svg" alt="boards"> -->
                <font-awesome-icon :icon="['fas', 'box-open']" class="nav-icon" />
                <span>{{ $t("Archives") }}</span>
            </router-link>
            <!-- <router-link to="/Manage" @click="selectTab(0)" :class="{ active: selected[0] }">
                <font-awesome-icon :icon="['fas', 'chart-gantt']" class="nav-icon" />
                <span>{{ $t("Manage") }}</span>
            </router-link> -->
            <router-link to="/refferal" @click="selectTab(1)" :class="`${this.$route.path == '/refferal' ? 'active ' : ''}`">
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/gantt.svg" alt="gantt"> -->
                <font-awesome-icon :icon="['far', 'paper-plane']" class="nav-icon" />
                <span>{{ $t("Refferal") }}</span>
            </router-link>

            <router-link to="/invoice" @click="selectTab(3)" :class="`${this.$route.path == '/invoice' ? 'active ' : ''}`">
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/list-tasks.svg" alt="list-tasks"> -->
                <font-awesome-icon :icon="['fas', 'file-invoice']" class="nav-icon" />
                <span>Invoice</span>
            </router-link>
        </div>
        <!---- <div class="searchbar">
                <img loading="lazy"  decoding="async" src="../assets/Images/icons/search-icon.svg" alt="search-icon" />
                <input type="text" placeholder="Search Tasks" />
            </div>-->
        <!-- <button class="squad">{{ $t("Hire Squad") }}</button> -->
    </div>
</template>

<script>
import { useStore } from "../store/index";
import InviteTeammate from "@/components/InviteTeammate.vue";

export default {
    name: "NavigationTabsView",
    components: {
        InviteTeammate,
    },
    data() {
        return {
            selected: [true, false, false, false, false],
            current: 0,
            showEmail: false,
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
        toggleEmail() {
            this.showEmail = !this.showEmail;
        },
    },
};
</script>

<style scoped lang="scss">
.navigation-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 48px; */
    padding-right: 5px;
    padding-left: 5px;
    border-bottom: 2px solid #edeff2;
    margin: 1rem;
    position: sticky;
    z-index: 5;

    button {
        position: absolute;
        top: -2px;
    }
}

.title {
    // width: 96%;
    // padding: 0 2rem;
    color: var(--Secondary-Grey-900, #0f172a);
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    //line-height: 42px;
    /* 116.667% */
    letter-spacing: -0.72px;
    padding-top: 0;
    margin-top: -1.5rem;

    &::after {
        content: "|";
        height: 40px;
        width: 2px;
        color: #656669;
        margin-left: 30px;
        margin-right: 30px;
        opacity: 0.2;
    }
}

.nav-links {
    width: 60%;
    display: flex;
    justify-content: flex-start;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    color: #adb8cc;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}

.squad {
    border-radius: 24px;
    background: #3361ff;
    display: flex;
    width: 166px;
    height: 44px;
    padding: 10px 27px 10px 26px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #fff;
}
</style>
