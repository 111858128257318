<template>
    <div v-if="showDetails" class="fixed top-0 left-0 z-10 w-full h-full fadeAnimation flex justify-end">
        <div class="fixed top-0 left-0 w-full h-full bg-[#00000086]" @click="toggleDetails"></div>
        <div class="animate-slide-from-left"></div>
        <div class="w-1/2 bg-[#F4F7FE] p-8 z-[5] slideAnimation">
            <div class="flex justify-between h-[5vh]">
                <h1 class="text-[24px] font-bold">Essential Skills Template</h1>
                <font-awesome-icon @click="toggleDetails" class="text-[24px] text-[#7D8FB3] cursor-pointer" :icon="['fas', 'xmark']" />
            </div>
            <div class="h-[87vh] overflow-y-auto p-2">
                <p class="text-[14px] font-[700]">About</p>
                <p class="text-[14px] mt-8">
                    This assessment will help you hire an employee who can engage in problem solving and communicate well. This holistic assessment will also examine a candidate's time management
                    skills and provide insight into their personality.
                </p>
                <div class="flex gap-1 my-8">
                    <div class="bg-[#fff] w-1/2 p-4 flex flex-col rounded-l-[10px]">
                        <svg width="20px" height="20px" class="mb-3" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                            <path
                                d="M6.5 11.5465L12 2.54651L17.5 11.5465H6.5ZM17.5 22.5465C16.25 22.5465 15.1875 22.109 14.3125 21.234C13.4375 20.359 13 19.2965 13 18.0465C13 16.7965 13.4375 15.734 14.3125 14.859C15.1875 13.984 16.25 13.5465 17.5 13.5465C18.75 13.5465 19.8125 13.984 20.6875 14.859C21.5625 15.734 22 16.7965 22 18.0465C22 19.2965 21.5625 20.359 20.6875 21.234C19.8125 22.109 18.75 22.5465 17.5 22.5465ZM3 22.0465V14.0465H11V22.0465H3ZM17.5 20.5465C18.2 20.5465 18.7917 20.3048 19.275 19.8215C19.7583 19.3382 20 18.7465 20 18.0465C20 17.3465 19.7583 16.7548 19.275 16.2715C18.7917 15.7882 18.2 15.5465 17.5 15.5465C16.8 15.5465 16.2083 15.7882 15.725 16.2715C15.2417 16.7548 15 17.3465 15 18.0465C15 18.7465 15.2417 19.3382 15.725 19.8215C16.2083 20.3048 16.8 20.5465 17.5 20.5465ZM5 20.0465H9V16.0465H5V20.0465ZM10.05 9.54651H13.95L12 6.39651L10.05 9.54651Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                        <h4 class="font-[700] text-[14px] text-[#242424]">Category</h4>

                        <p class="text-[12px]">Essential Skills</p>
                    </div>
                    <div class="bg-[#fff] w-1/4 p-4 flex flex-col items-start">
                        <svg width="20px" height="20px" class="mb-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.06152 12C5.55362 8.05369 8.92001 5 12.9996 5C17.4179 5 20.9996 8.58172 20.9996 13C20.9996 17.4183 17.4179 21 12.9996 21H8M13 13V9M11 3H15M3 15H8M5 18H10"
                                stroke="#000000"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>

                        <h4 class="font-[700] text-[14px] text-[#242424]">Total Time</h4>

                        <p class="text-[12px]">56 min</p>
                    </div>
                    <div class="bg-[#fff] w-1/4 p-4 flex flex-col rounded-r-[10px]">
                        <svg width="20" height="20" class="mb-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M20.58 19.37L17.59 11.01C17.38 10.46 16.91 10.12 16.37 10.12C15.83 10.12 15.37 10.46 15.14 11.03L12.16 19.37C12.02 19.76 12.22 20.19 12.61 20.33C13 20.47 13.43 20.27 13.57 19.88L14.19 18.15H18.54L19.16 19.88C19.27 20.19 19.56 20.38 19.87 20.38C19.95 20.38 20.04 20.37 20.12 20.34C20.51 20.2 20.71 19.77 20.57 19.38L20.58 19.37ZM14.74 16.64L16.38 12.05L18.02 16.64H14.74ZM12.19 7.85C9.92999 11.42 7.89 13.58 5.41 15.02C5.29 15.09 5.16 15.12 5.04 15.12C4.78 15.12 4.53 14.99 4.39 14.75C4.18 14.39 4.3 13.93 4.66 13.73C6.75999 12.51 8.48 10.76 10.41 7.86H4.12C3.71 7.86 3.37 7.52 3.37 7.11C3.37 6.7 3.71 6.36 4.12 6.36H7.87V4.38C7.87 3.97 8.21 3.63 8.62 3.63C9.02999 3.63 9.37 3.97 9.37 4.38V6.36H13.12C13.53 6.36 13.87 6.7 13.87 7.11C13.87 7.52 13.53 7.86 13.12 7.86H12.18L12.19 7.85ZM12.23 15.12C12.1 15.12 11.97 15.09 11.85 15.02C11.2 14.64 10.57 14.22 9.97999 13.78C9.64999 13.53 9.58 13.06 9.83 12.73C10.08 12.4 10.55 12.33 10.88 12.58C11.42 12.99 12.01 13.37 12.61 13.72C12.97 13.93 13.09 14.39 12.88 14.75C12.74 14.99 12.49 15.12 12.23 15.12Z"
                                fill="#000000"
                            />
                        </svg>
                        <h4 class="font-[700] text-[14px] text-[#242424]">Language</h4>

                        <p class="text-[12px]">English</p>
                    </div>
                </div>
                <div class="mt-4 rounded-[10px] bg-[#fff]">
                    <div class="flex flex-col">
                        <div class="-m-1.5 overflow-x-auto">
                            <div class="p-1.5 min-w-full inline-block align-middle">
                                <div class="overflow-hidden">
                                    <table class="min-w-full divide-y divide-gray-200">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="px-6 py-3 text-start text-xl font-medium text-gray-8">Included Tests</th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th scope="col" class="px-6 py-3 text-start text-l font-medium text-gray-8">Test</th>
                                                <th scope="col" class="px-6 py-3 text-start text-l font-medium text-gray-8">Duration</th>
                                            </tr>
                                        </thead>
                                        <tbody class="divide-y divide-gray-200">
                                            <tr v-for="(assessment, index) in assessments" :key="index">
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#2371b6]">
                                                    {{ assessment.name }}
                                                </td>
                                                <td class="px-6 py-4 flex gap-2 items-center whitespace-nowrap text-sm text-gray-800">
                                                    {{ assessment.duration }}
                                                    <svg width="20px" height="20px" class="mb-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M5.06152 12C5.55362 8.05369 8.92001 5 12.9996 5C17.4179 5 20.9996 8.58172 20.9996 13C20.9996 17.4183 17.4179 21 12.9996 21H8M13 13V9M11 3H15M3 15H8M5 18H10"
                                                            stroke="#000000"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MoreDetails",
    props: {
        assessment: {
            type: Object,
            required: true,
        },
        showDetails: {
            type: Boolean,
            required: true,
        },
        toggleDetails: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            assessments: [
                {
                    name: "Time Management 2.0",
                    duration: 14,
                },
                {
                    name: "Communication",
                    duration: 14,
                },
                {
                    name: "Teamwork",
                    duration: 14,
                },
                {
                    name: "Problem Solving",
                    duration: 14,
                },
            ],
        };
    },
};
</script>

<style scoped>
.slideAnimation {
    animation: slide 0.3s ease-in;
}
.hideAnimation {
    animation: hide 0.3s ease-out;
    display: none;
}
@keyframes slide {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes hide {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        transform: translateX(50%);
        opacity: 0;
    }
}

.fadeAnimation {
    animation: fade 0.3s ease-in;
}
.fadeOutAnimation {
    animation: fadeOut 0.3s ease-out;
    display: none;
}
@keyframes fade {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}
</style>
